<template lang="pug">
  v-footer
    v-col(class="text-center white--text" cols="12")
      | &copy; Телефония {{ new Date().getFullYear() }}
</template>

<script>
export default {
  name: "Footer"
};
</script>