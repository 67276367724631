<template lang="pug">
  v-app-bar(app absolute flat color="transparent" height="112" dark)
    v-img(
      alt="Telphin Logo"
      class="header__logo"
      src="@/assets/img/logo.png"
    )

    v-spacer

    v-btn(
      color="black"
      outlined
      @click="logout"
    )
      | Выйти
      
</template>

<script>
export default {
  name: "Header",
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    }
  }
};
</script>