import Axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    widgets: [],
    error: false
  },
  getters: {
    widgets: state => state.widgets,
    error: state => state.error
  },
  mutations: {
    SET_WIDGETS: (state, payload) => state.widgets = payload,
    SET_ERROR: (state, error) => state.error = error
 },
  actions: {
    all: async ({ commit }) => {
      try {
        const response = await Axios.get("/api/token");
        commit("SET_WIDGETS", response.data.reverse());
      } catch (e) {
        commit("SET_ERROR", e);
      }
    },

    get: async ({ commit }, payload) => {
      try {
        const response = await Axios.get(`/api/token/${payload}`);
        commit("SET_WIDGETS", response.data);
      } catch (e) {
        commit("SET_ERROR", e);
      }
    },

    create: async ({ commit }, payload) => {
      try {
        await Axios.post("/api/token", payload);
        return true;
      } catch (e) {
        commit("SET_ERROR", e);
        return false;
      }
    },

    update: async ({ commit }, payload) => {
      console.log("UPDATE", payload);
      try {
        await Axios.put(`/api/token/${payload.id}`, payload);
        return true;
      } catch (e) {
        commit("SET_ERROR", e);
        return false;
      }
    },

    delete: async ({ commit }, payload) => {
      try {
        await Axios.delete(`/api/token/${payload}`);
        return true;
      } catch (e) {
        commit("SET_ERROR", e);
        return false;
      }
    },

    deleteChat: async ({ commit }, payload) => {
      try {
        await Axios.delete(`/api/token/${payload.token_id}/chat/${payload.chat_id}`);
        return true;
      } catch (e) {
        commit("SET_ERROR", e);
        return false;
      }
    },

    clear: async ({ commit }) => {
      commit("SET_WIDGETS", []);
    }
  }
};
