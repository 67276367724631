<template lang="pug">
  include ../../plugins/bemto.pug

  widgets

</template>

<script>
import Widgets from "@/components/userApp/widgets/Widgets";

export default {
  name: "Dashboard",
  components: {
    Widgets
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {}
};
</script>
