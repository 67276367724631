<template lang="pug">
  include ../../plugins/bemto.pug

  v-container.main__content
      v-row
        v-col
          v-img(
            alt="Telphin Logo"
            class="fLogo"
            src="@/assets/img/logo.png"
          )
      v-row.pt-3
        v-col.fTitle
          | Быстрые уведомления и оперативность в любимом мессенджере.

      v-row
        v-col
          +b.V-FORM(v-model="formValid").fLogin
            v-alert(
              v-if="error"
              v-model="error.value"
              dense
              outlined
              dismissible
              color="error"
              transition="slide-y-transition"
            ).ma-0.mb-2
              | {{ error.message }}

            +e.DIV.title
              | Вход
            +e.DIV.subtitle
              | Введите данные от аккаунта администратора виртуальной АТС.

            +e.V-TEXT-FIELD.input(
              v-model.trim="form.login"
              type="text"
              label="Логин"
              autocomplete="false"
              :rules="[v => !!form.login || 'Поле не заполнено']"
            ).pt-6
            +e.V-TEXT-FIELD.input(
              v-model.trim="form.password"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[v => !!form.password || 'Поле не заполнено']"
              label="Пароль"
              @click:append="showPassword = !showPassword"
            )

            v-row.pt-3
              v-col
                +e.V-BTN(
                  color="primary"
                  :disabled="!isValidForm"
                  :loading="authProccess"
                  @click="auth"
                ).btn.white--text
                  | Войти
              v-col
                +e.V-BTN(
                  text
                  elevation="0"
                  @click="passwordRecovery"
                ).recovery
                  | Забыли пароль?


</template>

<script>
export default {
  name: "Login",
  data: () => ({
    formValid: false,
    authProccess: false,
    showPassword: false,
    form: {
      login: null,
      password: null
    },
    error: null
  }),
  computed: {
    isValidForm() {
      return this.formValid;
    }
  },
  methods: {
    async auth() {
      if (this.isValidForm) {
        this.authProccess = true;
        this.error = await this.$store.dispatch("user/login", this.form);
        this.authProccess = false;
      }
    },
    passwordRecovery() {
      window.open('https://apiproxy.telphin.ru/login/restore/?application_id=7d8e57504ee340e0b9d70196519b3faa', '_blank');
    },
    joinLink() {
      window.open(
        "https://www.telphin.ru/order/?utm_source=bitrix24&utm_medium=refferal&utm_campaign=bitrix24", "_blank");
    }    
  }
};
</script>
