<template lang="pug">
  include ../../../../plugins/bemto.pug

  v-container.pa-0
    v-row.mt-0
      v-col.pb-0
        v-autocomplete(
          v-model="value.external_lines"
          :items="externalLines"
          label="Выберите кого отслеживать"
          :rules="[value => !!value.length || 'Поле не может быть пустым!']"
          :item-text="item => `${item.name}`"
          :item-value="item => item"
          multiple
        ).pt-0
    v-row.mt-0
      v-col.pt-0
        v-checkbox(
          v-model="value.voicemail"
          label="Уведомления голосовой почты"
          :rules="[(value.voicemail || callNotification) || 'Выберите хотя бы один из типов уведомлений.']"
        ).ma-0.mt-4
          template(v-slot:label)
            | Уведомления голосовой почты
            v-tooltip(color="primary" top)
              template(v-slot:activator="{ on }")
                v-icon(
                  class="ml-2"
                  color="primary"
                  v-on="on"
                )
                  | help_outline
              span
                | Уведомления об оставленных голосовых сообщениях
    v-row.mt-0
      v-col.py-0
        v-row
          v-col.py-0
            v-checkbox(
              label="Уведомления о вызовах"
              v-model="callNotification"
              validate-on-blur	
              :rules="[(value.voicemail || callNotification) || 'Выберите хотя бы один из типов уведомлений.']"
            )
        v-row(v-if="callNotification").pl-7.mt-0
          v-col.py-0
            v-radio-group(
              v-model="value.call_notification"
              :rules="[callNotification  && ['all', 'received', 'missed'].includes(value.call_notification) || 'Поле не может быть пустым']"
              row
            ).mt-0
              v-radio(label="Все" value="all")
              v-radio(label="Принятые" value="received")
              v-radio(label="Пропущенные" value="missed")
        v-row(v-if="value.call_notification ==='missed'").pl-7.mt-0
          v-col.col-sm-6.col-md-6.col-12.py-0
            v-switch(
              label= "Игнорировать вызовы с ожиданием короче"
              v-model="value.missed_type"
              false-value = false
              true-value= true
            ).mt-0
          v-col(v-if="value.missed_type == true").col-sm-6.col-md-4.col-12.py-0
            v-text-field(
              v-model.number="value.missed_seconds"
              :rules="[rules.missed_seconds.regex]"
              type="Number"
              min = 1
              max = 60
            ).mt-0
              template(v-slot:append)
                | секунд
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExternalLineType",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      externalLines: "externalLines/lines"
    }),
    callNotification: {
      get: function() {
        return this.isCallNotification;
      },
      set: function(newValue) {
        this.isCallNotification = newValue;
        if (!newValue) {
          this.value.call_notification = "";
          this.value.missed_type = false;
          this.value.missed_seconds = 0;
        }
      }
    }  
  },
  data: () => ({
    isCallNotification: false,
    rules: {
      missed_seconds: { 
        regex: v => /^([1-9]{1}|[1-5]{1}\d{1}|60)$/.test(v) || 'Диапазон 1-60 секунд'
      }
    }
  }),
  created() {
    if (["all", "received", "missed"].includes(this.value.call_notification)) {
      this.isCallNotification = true;
    }
    if(["missed"].includes(this.value.call_notification) && this.value.missed_seconds != 0 ) {
      this.value.missed_type = true;
    }
    if (["all", "received"].includes(this.value.call_notification)) {
      this.value.missed_seconds = 0;
      this.value.missed_type = false;
    }
    if (this.value.missed_type === false) {
      this.value.missed_seconds = 0;
    }
  },
  updated() {
    if (["all", "received"].includes(this.value.call_notification)) {
      this.value.missed_seconds = 0;
      this.value.missed_type = false;     
    }
    if (this.value.missed_type === false) {
      this.value.missed_seconds = 0;
    }
  },
  methods: {}
};
</script>