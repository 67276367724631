import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

// modules
import user from "./modules/user.js";
import externalLines from "./modules/externalLines.js";
import extensions from "./modules/extensions.js";
import widget from "./modules/widget.js";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    clear: ({ dispatch }) => {
      dispatch("user/clear");
      dispatch("widget/clear");
      dispatch("externalLines/clear");
      dispatch("extensions/clear");
    }
  },
  modules: {
    user,
    widget,
    externalLines,
    extensions
  },
  plugins: [createPersistedState(
    {
      paths: ["user"]
    }
  )]
});