import Vue from "vue";
import VueRouter from "vue-router";

//modules
import User from '@/router/modules/user';

Vue.use(VueRouter);

const routes = [...User.routes];

const router = new VueRouter({
  mode: "history",
  routes
});

if (User.beforeEach) {
  router.beforeEach(User.beforeEach);  
}

export default router;