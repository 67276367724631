import Axios from "@/plugins/axios";
import router from "@/router";

const initialState = () => ({
  user: {
    login: null,
    auth: false,
    access: null
  },
  error: {
    value: false,
    message: null
  }
});
const error = (value, text) => ({
  value: value,
  message: text
});

export default {
  namespaced: true,

  state: initialState(),
  getters: {
    user: state => state.user,
    error: state => state.error,
  },
  mutations: {
    SET_USER: (state, payload) => (state.user = payload),
    SET_ERROR: (state, error) => (state.error = error),
    LOGOUT: state => (state.user = initialState())
  },
  actions: {
    login: async ({ commit }, payload) => {
      try {
        const response = await Axios.post("/auth", payload);
        commit("SET_USER", response.data);
        router.push("/dashboard");
      } catch (e) {
        if (e.response) {
          if (e.response.data.status === "limit_exceeded") {
            return error(true, "Исчерпан лимит по количеству подключенных приложений. Для расширения лимита обратитесь в тех. поддержку support@telphin.ru");
          }
          if (e.response.data.status === "invalid_grant") {
            return error(true, "Неверный логин или пароль.");
          }
          if (e.response.data.status === "user_access_type_deny") {
            return error(true, "Отказано в доступе: недостаточный тип доступа пользователя.");
          }
          if (e.response.status === 500) {
            return error(true, "Внутренняя ошибка сервера.");
          }
          return error(true, "Ошибка! попробуйте позже.");
        }
      }
    },

    teleoAuth: async ({ commit }, payload) => {
      try {
        const response = await Axios.post("/teleo/auth", null, {
          params: payload
        });
        commit("SET_USER", response.data);
        router.push("/dashboard");
      } catch (e) {
        if (e.response) {
          console.log(e.response);
          if (e.response.data.status === "limit_exceeded") {
            return error(true, "Исчерпан лимит по количеству подключенных приложений. Для расширения лимита обратитесь в тех. поддержку support@telphin.ru");
          }
          if (e.response.data.status === "invalid_grant") {
            return error(true, "Неверный логин или пароль.");
          }
          if (e.response.data.status === "user_access_type_deny") {
            return error(true, "Отказано в доступе: недостаточный тип доступа пользователя.");
          }
          if (e.response.status === 500) {
            return error(true, "Внутренняя ошибка сервера.");
          }
          return error(true, "Ошибка! попробуйте позже.");
        }
      }
    },

    logout: async ({ dispatch, commit }) => {
      dispatch("clear", null, { root: true });
      await Axios.post("/logout");
      commit("LOGOUT");
      router.push("/");
    },

    setError: ({ commit }, payload) => {
      if (!payload) {
        commit("SET_ERROR", initialState().error);
      } else {        
        commit("SET_ERROR", payload);
      }
    }
  }
};