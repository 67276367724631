import axios from "axios";
import env from "@/config/index.js";
import store from "@/store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: env.API_URL,
  timeout: 10 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  headers: {
    "Content-type": "application/json"
  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response.status === 401 && error.response.config.url != "/auth") {
      if (store.state.user.user.auth) {
        await store.dispatch("user/logout");        
      }
    }
    return Promise.reject(error);
  }
);

export default _axios;
