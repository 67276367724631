import development from "@/config/development.js";
import production from "@/config/production.js";
import staging from "@/config/staging.js";

const env = process.env.NODE_ENV || "development";

const config = {
  development,
  production,
  staging
};

export default config[env];
