  <template lang="pug">
  include ../../../plugins/bemto.pug

  +b.V-CONTAINER.wList.pa-0
    +e.V-CARD.content
      +e.V-CARD-TITLE.title.px-0.pt-0
        | Мои Токены

      +e.V-CARD-SUBTITLE.subtitle.px-0.pt-2.pb-2
        | Укажите настройки для получения уведомлений в Телеграме.
        |  Созданный токен необходимо отправить 
        +e.A(:href="botUrl" target="_blank") Телеграм Боту
        |  , либо использовать сгенерированную ссылку или qr код.

      v-card-actions.px-0.pb-0
        v-btn(
          outlined
          color="primary"
          @click="add"
          :disabled="isReadOnly"
        )
          | Добавить
      v-card-text(v-if="settings.length > 0").mt-6.pa-0
        v-expansion-panels(v-model="activePanel" accordion)
          +b.V-EXPANSION-PANEL(
            v-for="(item, index) in settings"
            :key="index"
          ).wItem
            +e.V-EXPANSION-PANEL-HEADER.title
              | {{ item.label }}
  
            +e.V-EXPANSION-PANEL-CONTENT.content
              +e.V-ROW(v-if="item.uuid").token-items.mt-0.mb-1
                v-col.py-0
                  +e.SPAN(
                    v-clipboard:copy="item.uuid"
                    v-clipboard:success="onTokenCopy"
                  ).token
                    span.grey-text.ma-0
                      | Токен: 
                    | {{ item.uuid.substr(0, 10) + "..." }}

                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(
                        icon
                        v-clipboard:copy="item.uuid"
                        v-clipboard:success="onTokenCopy"
                        v-bind="attrs"
                        v-on="on"
                      )
                        v-icon
                          | content_copy
                    span
                      | Копия токена для отправки боту
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(
                        icon
                        v-clipboard:copy="tokenUrl(item.uuid)"
                        v-clipboard:success="onLinkCopy"
                        v-bind="attrs"
                        v-on="on"
                      )
                        v-icon(size="22")
                          | share
                    span
                      | Ссылка для перехода в телеграмм и передачи токена боту
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(
                        icon
                        @click.stop="qrModal = true; qrLink=tokenUrl(item.uuid)"
                        v-bind="attrs"
                        v-on="on"
                      )
                        v-icon(size="22")
                          | qr_code_scanner
                    span
                      | QR-код для перехода в телеграмм и передачи токета боту

              +e.V-TABS(
                v-if="item.uuid"
                v-model="item.tab"
                :show-arrow="false"
              ).tabs.mt-1
                v-tab(key="tab1")
                  | Настройки
                v-tab(key="tab2")
                  | Пользователи
              +e.V-TABS-ITEMS(v-model="item.tab").tabs-items
                v-tab-item
                  v-form(:ref="index" :readonly="isReadOnly")
                    v-row.mt-2
                      v-col
                        v-text-field(
                          v-model.trim="item.label"
                          label="Название"
                          :rules="[value => !!value || 'Название не введено!']"
                          autofocus
                        ).pt-0
                    v-row.mt-1
                      v-col
                        v-autocomplete(
                          v-model="item.type"
                          :items="types"
                          :rules="[value => !!value || 'Выберите тип!']"
                          item-text="label"
                          item-value="value"
                          label="Тип"
                        ).pt-0

                    component(
                      v-model="settings[index].data"
                      :is="components[getTypeIDByValue(item.type)]"
                    ).pa-0

                    v-row.mt-6
                      v-col
                        v-divider

                    v-row
                      v-col
                        v-btn(
                          color="primary"
                          @click="update(index)"
                          :disabled="isReadOnly"
                        ).mr-4.px-4
                          | Сохранить
                        v-btn(
                          outlined
                          color="red accent-2"
                          class="red--text text--accent-2 px-2"
                          @click="removeRule(index)"
                          :disabled="isReadOnly"
                        )
                          | Удалить
                v-tab-item
                  +e.V-SIMPLE-TABLE(fixed-header).chats
                    template(v-slot:default)
                      thead
                        tr
                          th
                            | Имя
                          th(v-if="item.chats.length > 0")
                      tbody(v-if="item.chats.length > 0")
                        +b.TR(
                          v-for="(chat, index) in item.chats"
                          :key="index"
                        ).chat
                          +e.TD.name
                            template(v-if="chat.username != ''")
                              +e.A(:href="`https://t.me/${chat.username}`" target="_blank").name--link
                                | {{ (chat.first_name + " " + chat.last_name).trim() }}
                            template(v-else)
                                | {{ (chat.first_name + " " + chat.last_name).trim() }}
                          +e.TD.action
                            v-btn(
                              outlined
                              color="red accent-2"
                              class="red--text text--accent-2 px-2"
                              @click="deleteChat(chat)"
                              :disabled="isReadOnly"
                            )
                              | Удалить
                      tbody(v-else align="center")
                        tr
                          td
                            | Пользователей нет.

    v-snackbar(
      v-model="snackbar"
      :timeout="1000"
      absolute
      top
      color="success"
    )
      | {{ snackbarText }}

    v-dialog(
      v-model="qrModal"
      max-width="520"
    )
      +b.V-CARD.qr
        +e.V-CARD-TITLE.title.pt-0
          | Ваш QR-код
        +e.V-CARD-SUBTITLE.subtitle.px-0.pt-5
          | Отсканируйте код камерой вашего телефона или специальным приложением для считывания QR-кодов.
        +e.V-CARD-TEXT.content
          vue-qr(
            :logoSrc="qrLogo"
            :text="qrLink"
            :margin="0"
            :logoScale="0.3"
            :logoMargin="5"
            :dotScale="1"
            :correctLevel="2"
            :logoCornerRadius="30"
            :whiteMargin="false"
            colorLight="#F3FAFC"
            colorDark="#113151"
          )
        +e.V-CARD-ACTIONS.actions.py-0
          v-btn(
            color="primary"
            @click="qrModal = false"
          ).px-6
            | Закрыть
</template>

<script>
import { mapGetters } from "vuex";
import PhoneType from "@/components/userApp/widgets/types/PhoneType";
import QueueType from "@/components/userApp/widgets/types/QueueType";
import ExternalLineType from "@/components/userApp/widgets/types/ExternalLineType";
import env from "@/config/index.js";
import VueQr from "vue-qr";
import qrLogo from "@/assets/img/qr-logo.png";
import Vue from "vue";

const defaultSettingLine = () => ({
  type: null,
  label: "Новый токен",
  data: {
    call_notification: "",
    proccess_local_call: false,
    voicemail: false,
    phones: [],
    queues: [],
    external_lines: [],
    missed_seconds :0,
  },
  chats: [],
  tab: 0
});

const defaultName = (token) => {
  let name = "Уведомления ";
  if (token.data.call_notification === "all") {
    name += "обо всех звонках на ";
  } else if (token.data.call_notification === "received") {
    name += "о принятых звонках на ";
  } else {
    name += "о пропущенных звонках на ";
  }
  if (token.type === "phone") {
    const phones = token.data.phones;
    name += `добавочном ${phones[0].name}`;
    if (phones.length > 1) name += " и др.";
  } else if (token.type === "queue") {
    const queues = token.data.queues;
    name += `очереди ${queues[0].name}`;
    if (queues.length > 1) name += " и др.";
  } else {
    const lines = token.data.external_lines;
    name += `внешнем номере ${lines[0].name}`;
    if (lines.length > 1) name += " и др.";
  }
  return name;
}

export default {
  name: "Widgets",
  components: {
    PhoneType,
    ExternalLineType,
    QueueType,
    VueQr
  },
  data: () => ({
    components: [
      "PhoneType",
      "QueueType",
      "ExternalLineType"
    ],
    panel: undefined,
    settings: [],
    botUrl: env.TG_BOT_URL,
    snackbar: false,
    qrModal: false,
    qrLogo,
    qrLink: "",
    snackbarText: "",
  }),
  computed: {
    ...mapGetters({
      phones: "extensions/phones",
      queues: "extensions/queues",
      widgets: "widget/widgets",
      user: "user/user"
    }),
    types() {
      return [
        {
          id: 0,
          value: "phone",
          label: "Добавочный"
        }, {
          id: 1,
          value: "queue",
          label: "Очередь"
        }, {
          id: 2,
          value: "external_line",
          label: "Внешний номер"
        }
      ];
    },
    activePanel: {
      get: function() {
        return this.panel;
      },
      set: function(panel) {
        this.panel = panel;
      }
    },
    isReadOnly() {
      return this.user.access === "read_only";
    }
  },
  async mounted() {
    await this.$store.dispatch("extensions/all");
    await this.$store.dispatch("externalLines/all");
    await this.load();
  },
  methods: {
    async load() {
      const activeSetting = this.settings[this.panel] || null;
      await this.$store.dispatch("widget/all");
      this.settings = this.widgets.map(w => {
        if (w.tab) return w;
        if (activeSetting && w.uuid == activeSetting.uuid) {
          Vue.set(w, "tab", activeSetting.tab);
        } else {
          Vue.set(w, "tab", 0);
        }
        //перевод из объектов в массив из id для подставления в поле 
        // w.data.phones = w.data.phones.map(ext => ext.id);
        // w.data.queues = w.data.queues.map(ext => ext.id);
        return w;
      });
    },
    add() {
      this.settings.unshift(defaultSettingLine());
      this.activePanel = 0;
    },
    async update(index) {
      if (this.$refs[index][0].validate()) {
        const widget = this.settings[index];
        switch (widget.type) {
          case "phone":
            widget.data.phones = widget.data.phones.reduce((accumm, curr) => {
              const ext = this.phones.find(e => e.id === curr.id);
              return [...accumm, ext];
            }, []);
            widget.data.queues = [];
            widget.data.external_lines = [];
            break;
          case "queue":
            widget.data.queues = widget.data.queues.reduce((accumm, curr) => {
              const ext = this.queues.find(e => e.id === curr.id);
              return [...accumm, ext];
            }, []);
            widget.data.phones = [];
            widget.data.external_lines = [];
            widget.data.proccess_local_call = false;
            break;
          case "external_line":
            widget.data.phones = [];
            widget.data.queues = [];
            widget.data.proccess_local_call = false;
            break;
        }
        if (widget.label == "Новый токен") {
          widget.label = defaultName(widget);
        }
        if (!widget.id) {
          const res = await this.$store.dispatch("widget/create", widget);
          if (res) {
            this.snackbarText = "Токен успешно создан.";
          } else {
            this.snackbarText = "Произошла ошибка. Попрбуйте позже.";
          }
          this.snackbar = true;
        } else {
          const res = await this.$store.dispatch("widget/update", widget);
          if (res) {
            this.snackbarText = "Токен успешно обновлен.";
          } else {
            this.snackbarText = "Произошла ошибка. Попрбуйте позже.";
          }
          this.snackbar = true;
        }
        await this.load();
      }
    },
    async removeRule(index) {
      const settings = this.settings[index];
      this.panel = null;

      if (settings.id) {
        const res = await this.$store.dispatch("widget/delete", this.settings[index].id);
        if (res) {
          this.snackbarText = "Токен удален.";
          await this.load();
        } else {
          this.snackbarText = "Произошла ошибка. Попрбуйте позже.";
        }
        this.snackbar = true;
      } else {
        this.settings.splice(index, 1);
      }
    },
    getTypeIDByValue(value) {
      const type = this.types.find(e => e.value == value);
      if (type) {
        return type.id;
      }
      return null;
    },
    onTokenCopy() {
      this.snackbar = true;
      this.snackbarText = " Токен успешно скопирован";
    },
    onLinkCopy() {
      this.snackbar = true;
      this.snackbarText = "Ссылка с токеном успешно скопирована";
    },
    tokenUrl(token) {
      return this.botUrl+'?start='+token;
    },
    async deleteChat(chat) {
        const res = await this.$store.dispatch("widget/deleteChat", chat);
        if (res) {
          this.snackbarText = "Пользователь токена удален.";
          await this.load();
        } else {
          this.snackbarText = "Произошла ошибка. Попрбуйте позже.";
        }
        this.snackbar = true;
    }
  }
}
</script>
