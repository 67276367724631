import Axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    lines: [],
    error: false
  },
  getters: {
    lines: state => state.lines,
    error: state => state.error
  },
  mutations: {
    SET_LINES: (state, payload) => state.lines = payload,
    SET_ERROR: (state, error) => state.error = error
  },
  actions: {
    all: async ({ commit }, payload) => {
      try {
        const response = await Axios.get("/api/external-lines", payload);
        commit("SET_LINES", response.data);
      } catch (e) {
        commit("SET_ERROR", e);
      }
    },

    clear: async ({ commit }) => {
      commit("SET_LINES", []);
    }
  }
};