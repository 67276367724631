<template lang="pug">
  v-app.public-app
    v-main.main
      router-view
    Footer.footer
</template>

<script>
import Footer from "@/components/publicApp/Footer";

export default {
  name: "PublicApp",
  components: {
    Footer
  }
};
</script>

<style lang="sass" src="@/sass/publicApp/main.sass" />