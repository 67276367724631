// root layouts app
import UserApp from '@/views/layouts/user/UserApp';
import PublicApp from '@/views/layouts/user/PublicApp';

//views
import Login from '@/views/user/Login.vue';
import Dashboard from '@/views/user/Dashboard.vue';

//store
import store from "@/store";

export default {
  routes: [
    {
      path: "/",
      component: PublicApp,
      children: [{
        path: "",
        component: Login,
      }],
      meta: {
        guest: true
      }
  }, {
      path: "/dashboard",
      component: UserApp,
      children: [{
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      }]      
    }, {
      path: "*",
      redirect: "/"
    }
  ],
  beforeEach: (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.state.user.user.auth) {
        next("/");
        return;
      }
    }
    
    if (to.matched.some((record) => record.meta.guest)) {
      if (store.state.user.user.auth) {
        next("/dashboard");
        return;
      } else {
        if (to.query && to.query["auth_hash"]) {
          store.dispatch("user/teleoAuth", { hash: to.query["auth_hash"] });
        }
      }
    }

    next();
  }
};