<template lang="pug">
  v-app.user-app
    Header.header
    v-main.main
      router-view
    Footer.footer
</template>

<script>
import Header from "@/components/userApp/Header";
import Footer from "@/components/userApp/Footer";

export default {
  name: "UserApp",
  components: {
    Header,
    Footer
  },
  created() {},
  methods: {}
};
</script>

<style lang="sass" src="@/sass/userApp/main.sass" />